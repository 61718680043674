<template>
	<div v-if="itemsToDisplay.length">
		<WwAccordionGrid
			compact-mobile-view
			:category="tempCategory"
			:number-of-items-on-first-load="numberOfItemsOnFirstLoad"
			:items="items"
			:items-per-page="itemsPerPage"
			:page="page"
			:loading="loading"
			@accordion-click="handleAccordionClick"
			@next-page="handleNextPage"
		>
			<template #items="{page, perPage}">
				<ChainCard
					v-for="(listing, index) in itemsToDisplay"
					:id="`${listing.id}-${category.name}`"
					:key="'chain-card-' + index"
					:result="listing"
					:state-name="category.name"
					:style="{'--index': index, '--perPage': perPage, '--page': page}"
					class="flex flex-col items-center justify-center flex-shrink-0 mx-auto observed md:mx-0"
				/>
			</template>
		</WwAccordionGrid>
	</div>
</template>

<script async>
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import { CHAIN_LIST } from '@/constants/search/listTypes.js'
import { BUSINESS } from '@/constants/search/resultTypes.js'

export default {
	components: {
		ChainCard: () => import('@/components/chains/ChainCard.vue'),
		WwAccordionGrid: () => import('@/components/UI/WwAccordionGrid.vue')
	},
	props: {
		category: {
			type: Object,
			required: true
		},
		numberOfItemsOnFirstLoad: {
			type: Number,
			default: 4
		},
		itemsPerPage: {
			type: Number,
			default: 4
		},
		loading: {
			type: Number,
			default: 0
		}
	},
	emits: [
		'accordion-click',
		'next-page'
	],
	data() {
		return {
			tempCategory: { ...this.category },
			tempListings: []
		}
	},
	computed: {
		isExpanded() {
			return this.category?.expanded
		},
		itemsToDisplay() {
			return this.isExpanded ? this.items : this.items?.slice(0, this.numberOfItemsOnFirstLoad)
		},
		items() {
			return this.tempListings || []
		},
		page() {
			return this.category?.page || 1
		}
	},
	watch: {
		'category.expanded'(newValue, _) {
			if (newValue) {
				this.updateItemsToDisplay()
			} else {
				setTimeout(() => { // wait for itemsToDispay to be updated before collapsing (prevents card transition from jumping when collapsing)
					this.updateItemsToDisplay()
				}, 250)
			}
		},
		'category.listings': {
			handler(newValue, _) {
				const stateName = this.category.state
				newValue.forEach((listing) => {
					const tempIndex = this.tempListings.findIndex((mappedlisting) => mappedlisting?.id === listing?.id)
					if (tempIndex === -1) { // if item not already in array, push it
						this.tempListings.push({
							title: listing?.chainShortNameOverride || listing?.name,
							image: listing?.chainLogoOverride || listing?.logo?.fileName || '',
							id: listing?.id,
							rating: listing?.rating || listing?.ratingOverall,
							reviewCount: listing?.reviewCount,
							link: listing?.url,
							hasOrdering: listing?.settings?.hasOrdering,
							imagePath: LISTING_IMAGES,
							address: listing?.address,
							category: BUSINESS,
							listType: CHAIN_LIST,
							stateName: stateName,
							recreational: listing?.recreational || false
						})
					}
				})
			},
			immediate: true
		}
	},
	methods: {
		updateItemsToDisplay() {
			this.tempCategory = { ...this.category }
		},
		handleAccordionClick(stateId) {
			this.$emit('accordion-click', stateId)
		},
		handleNextPage(stateId) {
			this.$emit('next-page', stateId)
		}
	}
}
</script>
